import {
  createBrowserRouter
} from 'react-router-dom';

import TyroPayment from '../pages/tyro';
import TyroKiosk from '../pages/tyro-kiosk';
import PairWithTerminal from '../pages/tyro-kiosk/pairwithterminal';
import NotFound from '../pages/error-pages/notFound';

export default createBrowserRouter([
  {
    path: '/',
    errorElement: <NotFound />,
  },
  {
    path: '/tyrointegration',
    element: <TyroPayment />,
  },
  {
    path: '/kiosk',
    element: <TyroKiosk />,

  },
  {
    path: '/kiosk/pairwithterminal',
    element: <PairWithTerminal />,

  },
]);