import notFoundImage from '../../assets/Error404.png'

export default function NotFound() {
  return (
    <div className="flex flex-col w-screen h-screen justify-center items-center space-y-8">
      <img src={notFoundImage} alt='Not Found' className='w-[30%]' />
      <small className='text-gray-700 text-3xl'>Page Not Found</small>
      <small className='text-sm text-gray-500'>The page you are looking was moved, removed, renamed, or might never exist!</small>
    </div>
  )
}