import React from 'react';
import { Box, Button, TextField } from '@mui/material';
import Loader from './Loader';
import { useFormik } from 'formik';
import * as yup from 'yup';


function KioskForm({ handleSubmit, isSubmitting, buttonStyle, buttonBackgroundColor }) {

    const validationSchema = yup.object({
        mid: yup
            .number('Enter your Mid')
            .required('Mid is required'),
        tid: yup
            .number('Enter your Tid')
            .required('Tid is required'),
    });

    const formik = useFormik({
        initialValues: {
            mid: '',
            tid: '',
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            handleSubmit(values)
        },
    });

    return (
        <div className='flex flex-col justify-center items-center w-screen h-screen'>
            <Box
                component="form"
                onSubmit={formik.handleSubmit}
                display="flex"
                flexDirection="column"
                alignItems="center"
                width="500px"
                height="320px"
                p={3}
                boxShadow={3}
                borderRadius={2}
                marginTop={4}
            >
                <TextField
                    id="mid"
                    name="mid"
                    label="MID"
                    variant="outlined"
                    margin="normal"
                    type='number'
                    fullWidth
                    value={formik.values.mid}
                    onChange={formik.handleChange}
                    error={formik.touched.mid && Boolean(formik.errors.mid)}
                    helperText={formik.touched.mid && formik.errors.mid}
                />
                <TextField
                    id="tid"
                    name="tid"
                    label="TID"
                    variant="outlined"
                    margin="normal"
                    type='number'
                    fullWidth
                    value={formik.values.tid}
                    onChange={formik.handleChange}
                    error={formik.touched.tid && Boolean(formik.errors.tid)}
                    helperText={formik.touched.tid && formik.errors.tid}
                />
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    style={{ ...buttonStyle, marginTop: '20px' }}
                    disabled={isSubmitting}
                    startIcon={isSubmitting && <Loader loaderColor={'FFFFFF'} />}>
                    {isSubmitting ? 'Pairing' : 'Pair With Terminal'}
                </Button>
            </Box>
        </div>
    )
}

export default KioskForm;