import React from 'react';

const Loader = ({ loaderColor }) => {

    const loaderStyle = loaderColor
        ? { color: `#${loaderColor}` }
        : {}; // Empty object if no buttonBackgroundColor is provided

    return (
        <div style={loaderStyle} className="animate-spin inline-block w-8 h-8 border-[3px] border-current border-t-transparent rounded-full dark:text-blue-500" role="status" aria-label="loading">
            <span className="sr-only">Loading...</span>
        </div>
    );
};

export default Loader;